import { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { IMaskInput } from 'react-imask';
import './App.css';

import Logo from './logo.png'
import Home from './pages/Home';
import Our from './pages/Our';
import Cost from './pages/Cost';

import SSRJson from './data/SSR.json';
import OurCustomPage from './pages/OurCustomPage';
import Reviews from './pages/Reviews';
import Contacts from './pages/Contacts';

function App() {

  const startPath = document.location.pathname.replaceAll('/', '');

  const pages = ['home', 'our', 'price', 'reviews', 'contact', 'customloading', 'customcorrect', 'customcorrectread', 'customdfvoice', 'custompesk', 'customdcp', 'price', 'reviews', 'contact', 'customschool', 'customlogoritmika', 'customrazvitie', 'customdens', 'customritmika', 'customteip', 'customlfm', 'customizo', 'customprivacy', 'customjobs', 'customrulisten'];

  const [page, setPage] = useState(startPath ? pages.includes(startPath) ? startPath : 'home' : 'home');
  const [customPageInfo, setCustomPageInfo] = useState('loading');
  const [modal, setModal] = useState(false);

  const checkMobile = () => {
    const viewPort = [window.screen.height, window.screen.width];
    
    if (viewPort[1] > 900) {
      document.querySelector('.App').classList.remove('mobile');
    }
    else {
      document.querySelector('.App').classList.add('mobile');
    }
  }

  const master = {
    get: {
      customPageInfo: customPageInfo
    },
    post: async function(params) {
      const req = await axios.post('https://aleftinka.ru/.api/index.php', qs.stringify(params));
      return req.data;
    },
    toPage: function(page, nav=true) {
      if (pages.includes(page)) {
        if (page.includes('custom')) {
          const customPath = page.replaceAll('custom', '');
          setCustomPageInfo(customPath);
          setPage('OurCustomPage');
        }
        else {
          setPage(page);
        }
      }
      else {
        setPage('home');
      }
      window.scrollTo(0, 0)
      if (nav === true) {
        window.history.pushState({page: page}, "Логопед Иваново - Алефтинка", page);
      }
    },
    SSRLoader: {
      data: null,
      get: async function() {
        if (master.SSRLoader.data === null) {
          return await master.SSRLoader.loading();
        }
        else {
          return master.SSRLoader.data;
        }
      },
      loading: async function() {
        master.SSRLoader.data = SSRJson;
        return SSRJson;
      }
    }
  }

  useEffect(() => {
    checkMobile();

    const path = document.location.pathname.replaceAll('/', '');

    if (path) {
    if (pages.includes(page)) {
      if (path.includes('custom')) {
        const customPath = path.replaceAll('custom', '');
        setCustomPageInfo(customPath);
        setPage('OurCustomPage');
      }
      else {
        setPage(path);
      }
    }
    else {
      setPage('home');
    }
    }
    
    window.onresize = () => {
      checkMobile();
    }

    window.onpopstate = () => {
      const newPath = document.location.pathname.replaceAll('/', '');
      master.toPage(newPath, false);
    }

    setInterval(function() {
      const scroll = window.pageYOffset;
      const header = document.querySelector('.Header');

      if (scroll > 0) {
        header.classList.add('inscroll');
      }
      else {
        header.classList.remove('inscroll');
      }
    }, 10);
  }, []);

  window.first = function() {
    setModal(true)
  }

  useEffect(() => {
    const jdiv = document.querySelector('jdiv');
    if (!jdiv) return;
    if (modal === true) {
      jdiv.style.display = 'none';
    }
    else {
      jdiv.style.display = '';
    }
  }, [modal]);

  const callmeplease = async () => {
    if (!document.querySelector('.ntel').value) {
      window.alert('Введите свой номер телефона');
      return;
    }
    await master.post({method: 'callmeplease', number: document.querySelector('.ntel').value, name: document.querySelector('.namer').value});
    window.alert('Спасибо за вашу заявку! Мы постараемся позвонить Вам в ближайшее время');
    setModal(false);
  }

  return (
    <div className="App">
      {modal === true && <div className='modal'>
        <div className='modalCard'>
          <div className='modalClose' onClick={() => setModal(false)}>
            ✕
          </div>
          <div style={{fontSize: 21}}>
            Записаться на диагностику
          </div>
          <div style={{fontSize: 18, opacity: 0.6}}>
            Оставьте нам свои контактные данные - мы позвоним вам и проконсультируем по первому занятию
          </div>
          <div style={{fontSize: 16, marginTop: 20}}>
            Номер телефона:
            <div>
              <input className='ntel' type={"tel"} placeholder='+7 000 000 00 00' />
            </div>
          </div>
          <div style={{fontSize: 16, marginTop: 20}}>
            Как к вам обращаться?
            <div>
              <input className='namer' type={"text"} placeholder='Ваше имя' />
            </div>
          </div>
          <div style={{marginTop: 40, display: 'flex'}}>
          <div className='Button' onClick={async () => await callmeplease()}>Заказать звонок</div>
          </div>
        </div>
        </div>}
      <div className='Header'>
        <img src={Logo} alt="" style={{marginRight: 30}} />
        <a href="./home" target="_self" onClick={(e) => { e.preventDefault(); master.toPage('home') }}>Главная</a>
        <a href="./our" target="_self" onClick={(e) => { e.preventDefault(); master.toPage('our') }}>Услуги</a>
        <a href="./price" target="_self" onClick={(e) => { e.preventDefault(); master.toPage('price') }}>Цены</a>
        <a href="./reviews" target="_self" onClick={(e) => { e.preventDefault(); master.toPage('reviews') }}>Отзывы</a>
        <a href="./contact" target="_self" onClick={(e) => { e.preventDefault(); master.toPage('contact') }}>Контакты</a>
        <div className='Button' onClick={() => window.first()}>Первый прием</div>
      </div>
      <div className='HeaderMobile'>
        <div className={'HeaderMobileItem ' + (page === 'home')} onClick={() => master.toPage('home')}>
          <div className='icon'>
          <svg       xmlns="http://www.w3.org/2000/svg"       width="28"       height="28"       viewBox="0 0 28 28"     >       <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">         <g>           <path d="M0 0H28V28H0z"></path>           <path             fill="currentColor"             fillRule="nonzero"             d="M15.13 2.691l8.936 8.49A3 3 0 0125 13.356v8.641a2.5 2.5 0 01-2.5 2.5h-6a1 1 0 01-1-1v-5.5a1.5 1.5 0 00-3 0v5.5a1 1 0 01-1 1h-6a2.5 2.5 0 01-2.5-2.5v-8.641a3 3 0 01.934-2.175l8.936-8.49a1.64 1.64 0 012.26 0zM14 4.377L5.311 12.63a1 1 0 00-.311.725v8.641a.5.5 0 00.5.5h5v-4.5a3.5 3.5 0 017 0v4.5h5a.5.5 0 00.5-.5v-8.641a1 1 0 00-.311-.725L14 4.377z"           ></path>         </g>       </g>     </svg>
          </div>
          <div className='text'>Главная</div>
        </div>
        <div className={'HeaderMobileItem ' + (page === 'our')} onClick={() => master.toPage('our')}>
        <div className='icon'>
          <svg       xmlns="http://www.w3.org/2000/svg"       width="28"       height="28"       viewBox="0 0 28 28"     >       <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">         <g fillRule="nonzero">           <path d="M0 0H28V28H0z" opacity="0.527"></path>           <path             fill="currentColor"             d="M10.41 5c1.333 0 1.333 2 0 2l-.558.005c-1.479.028-2.141.18-2.823.545-.64.342-1.137.839-1.48 1.48-.364.68-.516 1.343-.544 2.822L5 12.41v5.18c0 1.881.142 2.62.55 3.38.342.641.839 1.138 1.48 1.48.68.365 1.343.517 2.822.545l.558.005h7.18c1.881 0 2.62-.142 3.38-.55a3.543 3.543 0 001.48-1.48c.365-.68.517-1.343.545-2.822L23 17.59v-5.18l-.005-.558c-.028-1.479-.18-2.141-.545-2.823a3.543 3.543 0 00-1.48-1.48c-.68-.364-1.343-.516-2.822-.544L17.59 7l-.133-.007c-1.156-.124-1.156-1.862 0-1.986L17.59 5H18l.071.003.172.004c1.649.038 2.581.232 3.499.69l.172.089a5.543 5.543 0 012.3 2.3c.553 1.034.764 2.03.784 3.988l.002.336v5.18c0 2.183-.203 3.233-.786 4.324a5.543 5.543 0 01-2.3 2.3c-1.034.553-2.03.764-3.988.784L10.41 25c-2.183 0-3.233-.203-4.324-.786a5.543 5.543 0 01-2.3-2.3c-.553-1.034-.764-2.03-.784-3.988L3 12.41c0-2.183.203-3.233.786-4.324a5.543 5.543 0 012.3-2.3c.976-.522 1.92-.74 3.67-.78l.172-.003L10 5h.41zM15 17a1 1 0 010 2H9a1 1 0 010-2h6zm4-5a1 1 0 010 2H9a1 1 0 010-2h10zm-5-9a1 1 0 011 1v4a1 1 0 01-2 0V4a1 1 0 011-1z"           ></path>         </g>       </g>     </svg>
          </div>
          <div className='text'>Услуги</div>
        </div>
        <div className={'HeaderMobileItem ' + (page === 'price')} onClick={() => master.toPage('price')}>
          <div className='icon'>
          <svg       xmlns="http://www.w3.org/2000/svg"       width="28"       height="28"       fill="none"       viewBox="0 0 28 28"     >       <path         fill="currentColor"         fillRule="evenodd"         d="M10.174 5.008c-.531.009-.982.025-1.377.057-.771.063-1.243.182-1.613.371a4 4 0 00-1.748 1.748c-.189.37-.308.842-.371 1.614C5 9.58 5 10.584 5 12v4c0 1.417 0 2.42.065 3.203.063.772.182 1.243.371 1.613a4 4 0 001.748 1.748c.37.189.842.308 1.613.371C9.581 23 10.583 23 12 23h4c1.417 0 2.419 0 3.203-.065.772-.063 1.243-.182 1.613-.37a4 4 0 001.748-1.749c.189-.37.308-.841.371-1.613C23 18.419 23 17.417 23 16v-4c0-1.416 0-2.419-.065-3.202-.063-.772-.182-1.243-.371-1.614a4 4 0 00-1.748-1.748c-.37-.189-.842-.308-1.613-.371a21.177 21.177 0 00-1.377-.057A3.001 3.001 0 0115 7h-2a3.001 3.001 0 01-2.826-1.992zm7.658-2c.574.009 1.081.027 1.533.064.896.073 1.66.226 2.359.582a6 6 0 012.622 2.622c.356.7.51 1.463.583 2.359.071.874.071 1.958.071 3.321V16.044c0 1.363 0 2.447-.071 3.322-.074.895-.227 1.659-.583 2.358a6 6 0 01-2.622 2.622c-.7.356-1.463.51-2.359.583-.874.071-1.958.071-3.321.071H11.956c-1.363 0-2.447 0-3.321-.071-.896-.073-1.66-.226-2.359-.583a6 6 0 01-2.622-2.622c-.356-.7-.51-1.463-.583-2.358C3 18.49 3 17.407 3 16.044V11.956c0-1.363 0-2.447.071-3.321.074-.896.227-1.66.583-2.359a6 6 0 012.622-2.622c.7-.356 1.463-.51 2.359-.582.452-.037.96-.055 1.533-.064A3.001 3.001 0 0113 1h2c1.31 0 2.423.839 2.832 2.008zM12 4a1 1 0 011-1h2a1 1 0 110 2h-2a1 1 0 01-1-1zm-2 10.5a1 1 0 10-2 0 1 1 0 002 0zM9 9a1 1 0 110 2 1 1 0 010-2zm1 10a1 1 0 10-2 0 1 1 0 002 0zm3-5.5a1 1 0 100 2h6a1 1 0 100-2zM12 10a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zm1 8a1 1 0 100 2h4a1 1 0 100-2z"         clipRule="evenodd"       ></path>     </svg>
          </div>
          <div className='text'>Цены</div>
        </div>
        <div className={'HeaderMobileItem ' + (page === 'reviews')} onClick={() => master.toPage('reviews')}>
          <div className='icon'>
          <svg       xmlns="http://www.w3.org/2000/svg"       width="28"       height="28"       viewBox="0 0 28 28"     >       <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">         <g>           <path d="M0 0L28 0 28 28 0 28z"></path>           <path             fill="currentColor"             fillRule="nonzero"             d="M16.06 20.354c.298-.26.68-.404 1.076-.404h4.447c1.17 0 1.867-.697 1.867-1.867V8.75c0-1.17-.697-1.867-1.867-1.867h-14c-1.17 0-1.866.697-1.866 1.867v9.333c0 1.17.696 1.867 1.866 1.867H10.5c.816.235.816.235 1.051 1.051v3.3l4.51-3.947zm-5.752 5.934c-.551-.413-.858-1.142-.858-1.788v-2.45H7.583c-2.33 0-3.966-1.637-3.966-3.967V8.75c0-2.33 1.636-3.967 3.966-3.967h14c2.33 0 3.967 1.637 3.967 3.967v9.333c0 2.33-1.637 3.967-3.967 3.967h-4.272l-4.345 3.802c-.855.839-1.854 1.04-2.659.436z"           ></path>         </g>       </g>     </svg>
          </div>
          <div className='text'>Отзывы</div>
        </div>
        <div className={'HeaderMobileItem ' + (page === 'contact')} onClick={() => master.toPage('contact')}>
          <div className='icon'>
          <svg       xmlns="http://www.w3.org/2000/svg"       width="28"       height="28"       viewBox="0 0 28 28"     >       <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">         <g>           <path d="M0 0L28 0 28 28 0 28z"></path>           <path             fill="currentColor"             fillRule="nonzero"             d="M14 2c6.627 0 12 5.373 12 12s-5.373 12-12 12S2 20.627 2 14 7.373 2 14 2zm0 2C8.477 4 4 8.477 4 14s4.477 10 10 10 10-4.477 10-10S19.523 4 14 4zm4.949 6.316l-2 6a1 1 0 01-.633.633l-6 2a1 1 0 01-1.265-1.265l2-6a1 1 0 01.633-.633l6-2a1 1 0 011.265 1.265zm-2.53 1.265l-3.628 1.21-1.21 3.628 3.628-1.21 1.21-3.628z"           ></path>         </g>       </g>     </svg>
          </div>
          <div className='text'>Контакты</div>
        </div>
      </div>

      {page === 'home' &&
        <Home master={master} />
      }
      {page === 'our' && <Our master={master} />}
      {page === 'OurCustomPage' && <OurCustomPage master={master} needlyPage={customPageInfo} />}
      {page === 'price' && <Cost master={master} />}
      {page === 'reviews' && <Reviews master={master} />}
      {page === 'contact' && <Contacts master={master} />}
    </div>
  );
}

export default App;
