import { useEffect, useState } from "react";

const OurCustomPage = ({needlyPage, master}) => {
    const [pageId, setPageId] = useState('loading');
    const [code, setCode] = useState('');


    useEffect(async () => {
        const g = await master.SSRLoader.get();
        if (g[needlyPage]) {
            setPageId(needlyPage);
        }
    }, []);

    useEffect(async () => {
        const g = await master.SSRLoader.get();
        let masterCode = g[pageId];
        masterCode = masterCode.replaceAll('{buttonBuy}', "<div class='Button Twice' onClick='window.first()'>Записаться на диагностику</div>")
        masterCode += '<div style="margin-bottom: 100px"></div>'
        setCode(masterCode);
    }, [pageId]);

    return (
       <div style={{marginTop: 75}}>
            <div dangerouslySetInnerHTML={{__html: code}}>

            </div>
       </div>
    )
}

export default OurCustomPage;