import { cloneElement, useEffect } from "react";

const Our = function({master}) {
    useEffect(() => {
        
    }, []);

    return (
        <>
            <div className="homeContent" style={{marginTop: 75}}>
                <div style={{marginTop: 70, fontSize: 26, textAlign: 'center'}}>Наши услуги</div>
                <div style={{color: "rgba(0, 0, 0, 0.52)", fontSize: 18, textAlign: 'center'}}>Здесь предоставлены наиболее частые обращения к нам. Если какой-то услуги нет в этом списке - это не обозначает, что мы с этим не работаем</div>

                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <a className="OurItem tappable"  target='_blank' href='https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2Fu87mjlM2UAVwzef87t4f%2Fu840P4tgaEQUQ6qua4fXMz4qqA0%2FbjG%2BxjrsV40BPo1q%2FJ6bpmRyOJonT3VoXnDag%3D%3D&name=Cabinet.pdf&nosw=1' style={{background: '#005BA5', color: 'white', textDecoration: 'none'}}>
                        <div>
                        Кабинет адаптивного биоуправления
                        </div>
                    </a>
                    <div className="OurItem tappable" style={{background: '#005BA5', color: 'white', textDecoration: 'none'}} onClick={() => master.toPage('customrulisten')}>
                        <div>
                            Метод Tomatis
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customcorrect')}>
                        <div>
                            Коррекция нарушений звукопроизношения
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customcorrectread')}>
                        <div>
                            Коррекция нарушений письма и чтения
                        </div>
                    </div>
                    <div className="OurItem">
                        <div>
                            Работа над слоговой структурой
                        </div>
                    </div>
                    <div className="OurItem">
                        <div>
                            Развитие связной речи
                        </div>
                    </div>
                    <div className="OurItem">
                        <div>
                            Логопедический массаж
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('custompesk')}>
                        <div>
                            Пескотерапия
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customdfvoice')}>
                        <div>
                            Работа с общим недоразвитием речи
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customdcp')}>
                        <div>
                            Услуги логопеда по работе с детьми ДЦП
                        </div>
                    </div>
                </div>

                <div style={{marginTop: 100, fontSize: 26, textAlign: 'center'}}>Индивидуальные и групповые занятия</div>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <div className="OurItem tappable" onClick={() => master.toPage('customschool')}>
                        <div>
                            Подготовка к школе
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customrazvitie')}>
                        <div>
                            Развивающие занятия
                        </div>
                    </div>
                    <div className="OurItem">
                        <div>
                            Развитие мелкой моторики, памяти, мышления
                        </div>
                    </div>
                    <div className="OurItem">
                        <div>
                            Обучение чтению за 3/6 месяцев
                        </div>
                    </div>
                    <div className="OurItem">
                        <div>
                            Группа кратковременного пребывания
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customlogoritmika')}>
                        <div>
                            Логоритмика
                        </div>
                    </div>
                    <div className="OurItem">
                        <div>
                            Мозжечковая стимуляция
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 100, fontSize: 26, textAlign: 'center'}}>Другое</div>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
                    <div className="OurItem">
                        <div>
                            Услуги психолога
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customdens')}>
                        <div>
                            ДЭНС-терапия
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customritmika')}>
                        <div>
                            Веселая ритмика
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customteip')}>
                        <div>
                            Тейпирование
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customlfm')}>
                        <div>
                            Лого-фасциальный массаж
                        </div>
                    </div>
                    <div className="OurItem tappable" onClick={() => master.toPage('customizo')}>
                        <div>
                            ИЗОбразим-Ка
                        </div>
                    </div>
                </div>
                <div style={{marginTop: 100}}></div>
            </div>
        </>
    )
}

export default Our;