import HomePicture from '../homepicture.png';
import HometrueMobile from '../components/hometruemobile.png';
import Hometrue from '../components/Hometrue';
import Homecost from '../components/Homecost';

const Home = ({master}) => {
    return (
        <>
          <div className='PromoFirst'>
          <div style={{width: '100%', height: 'calc(100% - 50px)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'column', paddingTop: 50}}>
              <div style={{padding: 30}}>
              <div className='onlymobile' style={{marginTop: 40}}></div>
              <div style={{fontSize: 38, color: '#008EFF'}}>Логопедический центр</div>
              <div style={{fontSize: 38, color: '#4856A3'}}>Алефтинка</div>
              <div className='rightLine' style={{marginTop: 30}}>
                <span style={{color: '#005BA5'}}>Современное оборудование</span>
              </div>
              <div className='rightLine'>
                <span style={{color: '#005BA5'}}>Профессионалы своего дела</span>
                <div style={{color: 'rgba(0, 91, 165, 0.6)', fontSize: 13}}>работаем со ВСЕМИ деффектами речи</div>
              </div>
              <div className='rightLine'>
                <div style={{color: '#005BA5'}}>Кабинет адаптивного боуправления</div>
                <div style={{color: 'rgba(0, 91, 165, 0.6)', fontSize: 13}}>нейротренажёры с БОС</div>
              </div>
              <div className='rightLine'>
                <div style={{color: '#005BA5'}}>Хорошие отзывы</div>
                <div style={{color: 'rgba(0, 91, 165, 0.6)', fontSize: 13}}>нам можно доверять</div>
              </div>
              <div className='onlymobile' style={{marginBottom: 40}}></div>
              </div>
            </div>
            <div className='inmobilehide' style={{width: '100%', height: 'calc(100% - 50px)', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 50}}>
              <img src={HomePicture} alt='' width={'60%'} />
            </div>
          </div>
          <div className='homeContent'>
            <div style={{marginTop: 50, width: '100%'}}>
              <Hometrue />
              <center className='onlymobile'>
                <img src={HometrueMobile} alt='' width={'80%'} />
              </center>
            </div>
            <div className='rightLine' style={{marginTop: 60}}>
              <span style={{fontWeight: 300}}>Логопедические занятия – это система занятий, направленных на коррекцию нарушений всех сторон речи: фонетической, грамматической, лексической, прагматической</span>
            </div>
            <div style={{marginTop: 100, fontSize: 26}}>Как проходят занятия</div>
            <div style={{width: '100%'}}>
              <div style={{display: 'flex', marginTop: 40, alignItems: 'center', width: '100%'}}>
                <div style={{background: '#CCE8FF', padding: 15, borderRadius: '100%', paddingRight: 22, paddingLeft: 22, fontSize: 21, color: '#008EFF'}}>
                  1
                </div>
                <div style={{fontSize: 24, fontWeight: 300, marginLeft: 14}}>
                  Обследование
                </div>
              </div>
              <div style={{marginTop: '14px', color: '#005BA5'}}>Первый приём - это обеследование. Во время обследования логопед учитывает индивидуальные особенности и при наличии дефектов речи подбирает уникальные методы для коррекции нарушений</div>
              <div style={{display: 'flex', marginTop: 40, alignItems: 'center', width: '100%'}}>
                <div style={{background: '#CCE8FF', padding: 15, borderRadius: '100%', paddingRight: 22, paddingLeft: 22, fontSize: 21, color: '#008EFF'}}>
                  2
                </div>
                <div style={{fontSize: 24, fontWeight: 300, marginLeft: 14}}>
                  Коррекция нарушений
                </div>
              </div>
              <div style={{marginTop: '14px', color: '#005BA5'}}>Если в ходе обследования выяснилось, что у ребёнка есть нарушения речи - логопед подбирает <b>уникальный</b> и <b>индивидуальный</b> курс логопедических занятий</div>
            </div>
            <div style={{marginTop: 100, fontSize: 26}}>Индивидуальные методы занятий</div>
            <div style={{width: '100%'}}>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Специальная программа по запуску речи</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Курсы по подготовке к школе, развивающие занятия, обучение чтению и письму, обучение построению фразы с опорой на графический образ, обучение первичным коммуникативным навыкам и другие курсы, которые могут быть назначены по результатам консультации логопеда</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Постановка правильного звукопроизношения и автоматизация поставленных звуков речи</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Устранение нарушений письменной речи и процессов чтения</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Работа над просодикой речи: (высотой, силой, тембром голоса, интонационной выразительностью)</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Работа над лексико-грамматическим строем речи</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Работа с людьми после инсульта, черепно-мозговой травмы. С детьми РАС, ДЦП</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Нейропсихологическая коррекция нарушений речи</span>
            </div>
            <div className='rightLine' style={{marginTop: 30}}>
              <span style={{fontWeight: 300}}>Логофасциальный массаж (ЛФМ по методу Сахаровской)</span>
            </div>
            </div>

            <div style={{marginTop: 100}}></div>
          </div>
          <div style={{width: 'calc(100vw - 100px)', background: '#005BA5', paddingTop: '35px', paddingBottom: '35px', paddingRight: '50px', paddingLeft: '50px'}}>
            <div className='homeContent inmobilehomecontentbanner' style={{width: '900px', alignItems: 'flex-start'}}>
              <div style={{fontSize: 26, color: 'white'}}>Помните, что нельзя оставлять без внимания нарушения речи ребенка</div>
              <div style={{fontSize: 21, color: 'rgba(255, 255, 255, 0.6)', fontWeight: 300, marginBottom: 20}}>Запишитесь на первое занятие уже сейчас</div>
              <Homecost />
              <div className='Button Twice' style={{marginTop: 50}} onClick={() => window.first()}>Записаться на диагностику</div>
            </div>
          </div>
          <div className='footer'>
            <div style={{marginBottom: 10}}>ООО "ЮрСовет" (ИНН 3702587628)</div>
            <div style={{display: 'flex'}}>
            <div style={{textDecoration: 'underline', cursor: 'pointer', marginRight: 20}} onClick={() => master.toPage('customprivacy')}>
            Политика конфиденциальности
          </div>
          <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => master.toPage('customjobs')}>
            Вакансии
          </div>
            </div>
      </div>
        </>
    )
}

export default Home;