import { useEffect } from "react";

const Contacts = () => {

    useEffect(() => {
        
    }, [])

    return (
        <div className="homeContent" style={{marginTop: 75}}>
            <div style={{marginTop: 70, fontSize: 26, textAlign: 'center'}}>
                Контакты
            </div>
            <div style={{marginTop: 20, display: 'flex'}}>
                <div className="contactCard" style={{marginRight: 20}}>
                    <div>
                        <b>Номер телефона</b>
                    </div>
                    <div>
                        <a href="tel:+79016982171">+79016982171</a>
                    </div>
                </div>
                <div className="contactCard">
                    <div>
                        <b>E-mail</b>
                    </div>
                    <div>
                        pr@aleftinka.ru
                    </div>
                </div>
            </div>
            <div style={{marginTop: 30}}>
                <div className='Button' onClick={() => window.first()}>Позвоните мне</div>
            </div>
            <div style={{marginTop: 50, fontSize: 21, textAlign: 'center'}}>
                Где мы находимся?
            </div>
            <div>г.Иваново, ул. Станционная д.13, офис 1012 рядом с гипермаркетом Лента</div>
            <iframe style={{marginTop: 25, marginBottom: 50}} src="https://yandex.ru/map-widget/v1/?um=constructor%3Abcb1659900db561f0ac44cafb4368073b02162f13a669796a7cc8e6f86065eaa&amp;source=constructor" width="100%" height="492" frameborder="0"></iframe>
            
        </div>
    )
}

export default Contacts;