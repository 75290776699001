import { useEffect, useState } from "react";

const Const = ({master}) => {

    const [info, setInfo] = useState(null);
    const [loading, setLoading] = useState('');

    useEffect(async () => {
        const ssr = await master.SSRLoader.get();
        setLoading(ssr['loading']);

        const answer = await master.post({method: 'getcost'});
        setInfo(answer);
    }, []);

    return (
        <>
            <div className="homeContent" style={{marginTop: 75}}>
                <div style={{marginTop: 70, fontSize: 26, textAlign: 'center'}}>Цены</div>

                {info === null && <div dangerouslySetInnerHTML={{__html: loading}}>
                    
                </div>}
                {info !== null && <>
                    <table style={{marginTop: 50, marginBottom: 100}}>
                        <tr>
                        <th style={{width: '60%'}}>
                            Услуга
                        </th>
                        <th style={{width: '15%'}}>
                            Стоимость
                        </th>
                        <th style={{width: '25%'}}>
                            Время (мин)
                        </th>
                        </tr>
                        {
                            info.map(function(value, i) {
                                return (
                                    <tr key={i}>
                                        <th>
                                            {value.name}
                                        </th>
                                        <th dangerouslySetInnerHTML={{__html: value.cost}}>
                                            
                                        </th>
                                        <th dangerouslySetInnerHTML={{__html: value.duration}}>
                                            
                                        </th>
                                    </tr>
                                )
                            })
                        }
                    </table>
                </>}
                <div style={{textAlign: 'center', fontWeight: 400, fontSize: 12, marginBottom: 60, opacity: 0.6}}>Все цены, указанные на сайте приведены как справочная информация и не являются публичной офертой, определяемой положениями статьи 437 Гражданского кодекса Российской Федерации и могут быть изменены в любое время без предупреждения. Для получения подробной информации о стоимости, сроках и условиях действия акций, спец предложений и скидок просьба обращаться по указанным на сайте контактным телефонам.</div>
            </div>
        </>
    )
}

export default Const;