import { useEffect } from "react";

const Reviews = () => {
    
    useEffect(() => {
        eval('(function() { var d = document, s = d.createElement("script");     s.src = "https://aleftinka-1.disqus.com/embed.js";     s.setAttribute("data-timestamp", +new Date());     (d.head || d.body).appendChild(s);     })();     ')
    })

    return (
        <div className="homeContent" style={{marginTop: 75}}>
            <div style={{marginTop: 70, fontSize: 26, textAlign: 'center'}}>
                Отзывы
            </div>
            <div style={{marginTop: 25, width: '100%'}} id="disqus_thread"></div>
        </div>
    )
}

export default Reviews;